/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from 'react'
import './App.css'
import { Base64 } from 'crypto-js/enc-base64';
import MD5 from 'crypto-js/md5';
import Swal from 'sweetalert2';
import { HubConnectionBuilder } from '@microsoft/signalr';

function App() {
   var user = undefined;
   var signalRConnection = undefined;
   document.onvisibilitychange = () => { if (document.visibilityState === "visible") updateState(); }

   var stateLabelRef = useRef(null);

   useEffect(() => {
      stateLabelRef.current = document.getElementById("stateLabel");

      const getSelf = async () => {
         var response = await fetch(
            "user/self",
            {
               headers: { "Accept": "application/json" }
            });

         if (!response.ok) {
            user = undefined;
            await updateState();
            return;
         }

         user = await response.json();
         openSignalrConnection();
         await updateState();

         await checkNewUser();
      }

      getSelf();
   }, []);
      

   const logIn = () => {
      //TEST
      //const currentLocation = window.location.href;
      //window.location.href = `https://testaccount.maptek.com/auth/service/handover?next=${encodeURIComponent(currentLocation)}`;
      //PROD
      window.location.href = `https://testaccount.maptek.com/auth/service/handover?next=${encodeURIComponent('https://test.lithium.testaccount.maptek.com/')}`
   }

   const logOut = async () => {
      // There is no way for the Lithium website to log out of MA so fake it by
      // opening a new tab at the MA logout page and assuming it worked.
      user = undefined;

      signalRConnection?.stop();
      signalRConnection = undefined;

      var response = await fetch(
         "user/logout",
         {
            method: "OPTIONS"
         });

      await updateState();

      window.location.href = "https://testaccount.maptek.com/auth/account/logout"
   }

   // Gets a MaptekAccountUser from SessionManager
   

   async function openSignalrConnection() {
      signalRConnection = new HubConnectionBuilder()
         .withUrl('/changes')
         .build();

      signalRConnection.on('OnWorkspacesChanged', async function () {
         console.log('*** OnWorkspacesChanged');
         await updateState();
      });

      signalRConnection.start()
         .then(function () {
            console.log('SignalR connection started');
         })
         .catch(error => {
            console.error(error.message);
         });
   }

   function loginButtons() {
      if (user) {
         return (
            <div>
               <button id="loginButton" onClick={logIn} disabled>Log in</button>
               <button id="logoutButton" onClick={logOut} >Log out</button>
               <button onClick={() => { window.location.href = 'indexold.html'; }}>Go to old site</button>
            </div>

         );
      } else {
         return(
            <div>
               <button id="loginButton" onClick={logIn} >Log in</button>
               <button id="logoutButton" onClick={logOut} disabled>Log out</button>
               <button onClick={() => { window.location.href = 'indexold.html'; }}>Go to old site</button>
            </div>
         );
      }

   }

   
   async function updateTable() {

      // ##TODO## DTM Build cards here
      if (user) {
         var workspaces = await GetWorkspaces()

         let myDivs = ''
         let sharedDivs = ''

         workspaces = workspaces.reverse();

         for (const workspace of workspaces) {
            const myWorkspace = workspace.owner.id === user.id;
            const active = workspace.status == "Active";

            // Can only delete my workspaces
            let deleteButton = ""
            if (myWorkspace) {
               deleteButton = `<button class="deleteWorkspace ${workspace.id}">&#x2715</button>`;
            }

            // Can only open my workspaces or active workspaces
            let canOpen = ""
            if (myWorkspace || active) {
               canOpen = `openViewer ${workspace.id}`;
            }

            // Get a random placeholder image based on workspace name
            const hash = MD5(workspace.name.trim());
            const imgId = Math.abs(hash.words[0] % 8);

            let div = `<div class="workspaceCard">
               <img src="${await GravatorImageUrl(workspace.owner.userName)}" class="gravatar" title="${workspace.owner.userName}" />
               <p class="workspaceTitle">${workspace.name}</p>
               ${deleteButton}
               <img class="workspaceImage ${canOpen}" src="./placeholders/${imgId}.png" class="workspaceImage"/>
               <div class="viewerUsers">`

            let viewers = await GetViewers(workspace);
            for (const viewer of viewers) {
               div += `<img src="${await GravatorImageUrl(viewer.owner.email)}" class="gravatar" title="${viewer.owner.email}" />`
            }
                  //<img src="${await GravatorImageUrl(workspace.owner.userName)}" class="gravatar" title="${workspace.owner.userName}" />
                  //<img src="${await GravatorImageUrl(workspace.owner.userName)}" class="gravatar" title="${workspace.owner.userName}" />

            div += `</div>
            </div>`

            if (myWorkspace) {
               myDivs += div
            } else {
               sharedDivs += div
            }

            // rows += `<tr>`
            // rows += `<td>${workspace.id}</td>`
            // rows += `<td>${workspace.name}</td>`
            // rows += `<td>${workspace.status}</td>`

            // if (myWorkspace) {
            //    rows += `<td>Me</td>`
            // } else {
            //    rows += `<td>${workspace.owner.name}</td>`
            // }

            // // Viewers
            // rows += `<td>`

            // let viewers = []

            // if (workspace.status == 'Active') {
            //    viewers = await GetViewers(workspace);
            // }

            // for (const viewer of viewers) {
            //    rows += `<a class="targetBlank openViewer ${workspace.id} ${viewer.processId}" href='#'>${viewer.label}</a>`
            //    rows += `<img src="${await GravatorImageUrl(viewer.owner.email)}" class="gravatar" title="${viewer.owner.email}" />`
            //    rows += `<button class="deleteViewer ${workspace.id} ${viewer.processId}">Delete</button>`
            //    rows += '<br/>'
            // }

            // if (workspace.status == 'Active') {
            //    rows += `<button class="createViewer ${workspace.id}">Create new...</button><br/>`
            // }

            // rows += `</td>`
            
            // // Actions
            // rows += `<td>`

            // if (myWorkspace) {
            //    if (!workspace.associatedInstance) {
            //       rows += `<button class="activateWorkspace ${workspace.id}">Activate</button>`
            //    }

            //    if (workspace.associatedInstance) {
            //       rows += `<button class="deactivateWorkspace ${workspace.id}">Deactivate</button>`
            //    }

            //    rows += `<button class="deleteWorkspace ${workspace.id}">Delete</button>`
            // }
            // rows += `</td>`

            // rows += `</tr>`
         }

         // Add create workspace button
         myDivs += `<div class="workspaceCard createWorkspace"><p>New Workspace</p><h1>+</h1></div>`

         document.getElementById("myWorkspaces").innerHTML = myDivs;
         document.getElementById("sharedWorkspaces").innerHTML = sharedDivs;

         const openViewerButtons = document.querySelectorAll('.openViewer');
         openViewerButtons.forEach(button => {
            const workspaceId = parseInt(button.classList[2]);
            button.addEventListener('click', () => GetUserIntoViewer(workspaceId));
         })

         const createWorkspaceButtons = document.querySelectorAll('.createWorkspace');
         createWorkspaceButtons.forEach(button => {
            button.addEventListener('click', () => CreateWorkspace());
         })

         // const deleteViewerButtons = document.querySelectorAll('.deleteViewer');
         // deleteViewerButtons.forEach(button => {
         //    const workspaceId = parseInt(button.classList[1]);
         //    const processId = parseInt(button.classList[2]);
         //    button.addEventListener('click', () => DeleteViewer(workspaceId, processId));
         // })

         // const createViewerButtons = document.querySelectorAll('.createViewer');
         // createViewerButtons.forEach(button => {
         //    const id = parseInt(button.classList[1]);
         //    button.addEventListener('click', () => CreateViewer(id));
         // })

         // const activateWorkspaceButtons = document.querySelectorAll('.activateWorkspace');
         // activateWorkspaceButtons.forEach(button => {
         //    const id = parseInt(button.classList[1]);
         //    button.addEventListener('click', () => ActivateWorkspace(id));
         // })

         // const deactivateWorkspaceButtons = document.querySelectorAll('.deactivateWorkspace');
         // deactivateWorkspaceButtons.forEach(button => {
         //    const id = parseInt(button.classList[1]);
         //    button.addEventListener('click', () => DeactivateWorkspace(id));
         // })

         const deleteWorkspaceButtons = document.querySelectorAll('.deleteWorkspace');
         deleteWorkspaceButtons.forEach(button => {
            const id = parseInt(button.classList[1]);
            button.addEventListener('click', () => DeleteWorkspace(id));
         })
      }
      else {
         document.getElementById("myWorkspaces").innerHTML = '';
         document.getElementById("sharedWorkspaces").innerHTML = '';
      }
   }

   async function GetUserIntoViewer(workspaceId) {
      const delay = ms => new Promise(res => setTimeout(res, ms));

      Swal.fire({
         title: 'Opening workspace',
         html: 'Please wait...',
         allowEscapeKey: false,
         allowOutsideClick: false,
         didOpen: () => {
           Swal.showLoading()
         }
      });

      // This is a catch all function to get a user into a viewer
      while (true) {
         // Start workspace if it's not
         let workspace = await GetWorkspace(workspaceId);
         if (workspace.status != 'Active') {
            if (!await ActivateWorkspace(workspaceId)) {
               break;
            }

            // Workspace is activating, we keep checking until it's done
            while (true) {
               let workspace = await GetWorkspace(workspaceId);
               if (workspace.status != 'Active') {
                  console.log("Waiting for activation...")
                  await delay(1000);
               } else {
                  break;
               }
            }
         }

         // Check if we have a viewer
         let viewers = await GetViewers(workspace);
         for (const viewer of viewers) {
            if (viewer.owner.id == user.id) {
               // Viewer exists, open it
               await OpenViewer(workspaceId, viewer.processId);
               return;
            }
         }

         // Create viewer and then try again
         console.log("Creating viewer")
         if (!await CreateViewer(workspaceId)) {
            break;
         }
      }

      Swal.close();
      await ShowError("Failed to start viewer");
   }

   async function updateState() {
      if (user) {
         stateLabelRef.current.innerHTML =
            `Logged in to MA as ` +
            `<img src="${await GravatorImageUrl(user.email)}" class="gravatar loginGravatar" />` +
            `<strong>${user.email}</strong>`;

         document.getElementById("loginButton").disabled = true;
         document.getElementById("logoutButton").disabled = false;
      }
      else {
         stateLabelRef.current.innerHTML = "Logged out of MA"
         document.getElementById("loginButton").disabled = false;
         document.getElementById("logoutButton").disabled = true;
      }
      await updateTable();
      
   }

   async function checkNewUser() {
      if (user) {
         var workspaces = await GetWorkspaces()
         var anyWorkspaceOwned = false;
         for (const workspace of workspaces) {
            if (workspace.owner.id === user.id) {
               anyWorkspaceOwned = true;
               break;
            }
         }

         if (!anyWorkspaceOwned) {
            //bring up a panel
            await Swal.fire({
               title: 'Welcome to Lithium',
               text: 'Lithium is a platform that allows users to share, view, & process data. Workspaces can be created & shared among users & act as a "Project" in which Viewers can be attached. Each person can have their own viewer and look at the data contained in a workspace',
               icon: 'info',
            })
         }
      }
   }

   async function CreateWorkspace() {
      console.log("Create Workspace clicked");

      var workspaceName = await PromptUserForText(
         'Please enter a name for the new workspace',
         'Create Workspace',
         'E.g. Acme resource model, High walls');

      if (workspaceName === undefined) {
         return;
      }

      if (!workspaceName || !workspaceName.trim()) {
         await ShowValidationMessage('Please enter a name for the new workspace');
         return;
      }

      var response = await fetch(
         `workspaces?name=${encodeURIComponent(workspaceName.trim())}`,
         {
            headers: { "Accept": "application/json" },
            method: "POST",
         });

      if (!response.ok) {
         await ShowError('Workspace creation failed: ' + await response.text());
         return;
      }
   }

   async function ActivateWorkspace(id) {
      var response = await fetch(
         `workspaces/${id}/activate`,
         {
            headers: { "Accept": "application/json" },
            method: "OPTIONS",
         });

      if (!response.ok) {
         await ShowError('Workspace activation failed: ' + await response.text());
         return false;
      }
      return true;
   }

   async function DeactivateWorkspace(id) {
      var response = await fetch(
         `workspaces/${id}/deactivate`,
         {
            headers: { "Accept": "application/json" },
            method: "OPTIONS",
         });

      if (!response.ok) {
         await ShowError('Workspace deactivation failed: ' + await response.text());
         return;
      }
   }

   async function DeleteWorkspace(id) {
      const confirmed = await AreYouSure(
         'Delete Workspace',
         'This cannot be undone.<br/>The state of all viewers will be lost.');

      if (!confirmed) {
         return;
      }

      var response = await fetch(
         `workspaces/${id}`,
         {
            method: "DELETE",
         });

      if (!response.ok) {
         await ShowError('Workspace deletion failed: ' + await response.text());
         return;
      }
   }

   async function GetWorkspaces() {
      var response = await fetch(
         "workspaces",
         {
            headers: { "Accept": "application/json" }
         });

      if (!response.ok) {
         console.error(response.text);
         throw new Error('GetWorkspaces failed');
      }

      return await response.json();
   }

   async function GetWorkspace(id) {
      var response = await fetch(
         `workspaces/${id}`,
         {
            headers: { "Accept": "application/json" }
         });

      if (!response.ok) {
         console.error(response.text);
         throw new Error('GetWorkspace failed');
      }

      return await response.json();
   }

   async function GetWorkspaceManagerToken() {
      var response = await fetch(
         'auth/workspaceManagerToken',
         {
            headers: { "Accept": "application/json" }
         });

      if (!response.ok) {
         console.error(response.text);
         throw new Error('GetWorkspaceManagerJWT failed');
      }

      return await response.json();
   }

   async function GetViewers(workspace) {
      if (workspace?.associatedInstance?.viewersApi) {
         const controller = new AbortController();
         const timeoutId = setTimeout(() => controller.abort(), 1000);

         try {
            var response = await fetch(
               workspace.associatedInstance.viewersApi,
               {
                  signal: controller.signal,
                  method: "GET",
                  headers: {
                     "Accept": "application/json",
                     "Authorization": "Bearer " + await GetWorkspaceManagerToken()
                  },
               });

            if (response.ok) {
               return await response.json();
            }
            else {
               console.log('GetViewers failed ' + response.status);
            }
         }
         catch (error) {
            console.error(error);
            return [];
         }
      }

      return [];
   }

   function generateString(length) {
      const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   
      let result = "";
      const charactersLength = characters.length;
      for ( let i = 0; i < length; i++ ) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
   }

   async function CreateViewer(workspaceId) {
      // We want an id or random id for the viewer
      //##TODO## DTM Random string isn't the best solution
      const viewerLabel = generateString(16);

      if (!viewerLabel || !viewerLabel.trim()) {
         await ShowValidationMessage('Please enter a name for the new viewer');
         return;
      }

      // Tell SM to tell WM to create the viewer
      var response = await fetch(
         `workspaces/${workspaceId}/viewers?label=${encodeURIComponent(viewerLabel.trim())}`,
         {
            "Accept": "application/json",
            method: "POST",
         });

      if (!response.ok) {
         await ShowError('Viewer activation failed: ' + await response.text());
         return false;
      }
      return true;
   }

   async function DeleteViewer(workspaceId, processId) {
      // Tell SM to tell WM to delete the viewer
      var response = await fetch(
         `workspaces/${workspaceId}/viewers/${processId}`,
         {
            method: "DELETE",
         });

      if (!response.ok) {
         await ShowError('Viewer deletion failed: ' + await response.text());
         return;
      }
   }

   async function OpenViewer(workspaceId, viewerId) {
      const workspace = await GetWorkspace(workspaceId);
      const code = await CreateViewerAuthCode(workspace);

      const viewers = await GetViewers(workspace);
      const viewer = viewers.find(v => v.processId === viewerId);

      const redirectUrl = `${viewer.frontEndUrl}?viewPort=${viewer.viewPort}`;
      const url = `${viewer.frontEndUrl}/login?code=${encodeURIComponent(code)}&returnUrl=${encodeURIComponent(redirectUrl)}`;

      location.href = url;
   }

   async function CreateViewerAuthCode(workspace) {
      var response = await fetch(
         `workspaces/${workspace.id}/code`,
         {
            headers: { "Accept": "application/json" },
            method: "POST",
         });

      if (!response.ok) {
         await ShowError('Code creation failed: ' + await response.text());
         throw new Error('Code creation failed');
      }

      let json = await response.json();
      return json.replaceAll('"', '');
   }

   async function PromptUserForText(promptLabel, title, promptPlaceholder) {
      const { value: inputText, isDismissed: wasDismissed, dismiss: dismissReason } = await Swal.fire({
         title: title,
         input: 'text',
         inputLabel: promptLabel,
         inputPlaceholder: promptPlaceholder,
         showCancelButton: true,
      });

      if (wasDismissed) {
         return undefined;
      }

      return inputText;
   }

   async function ShowError(message) {
      await Swal.fire({
         title: 'Error',
         text: message,
         icon: 'error',
      })
   }

   async function ShowValidationMessage(message) {
      await Swal.fire({
         title: 'Oops',
         text: message,
         icon: 'info',
      })
   }

   // returns true if the user chose Yes, otherwise false
   async function AreYouSure(title, message) {
      const { isConfirmed: wasConfirmed } = await Swal.fire({
         title: title,
         html: message,
         showDenyButton: true,
         confirmButtonText: 'Yes',
         denyButtonText: 'No',
      });

      return wasConfirmed === true;
   }

   async function GravatorImageUrl(email) {
      if (!email) {
         return null;
      }

      const hash = MD5(email.trim().toLowerCase());
      const base64Encoded = hash.toString(Base64);
      return `https://www.gravatar.com/avatar/${base64Encoded}`;
   }

   


  return (
    <>
      <h1>Project Lithium</h1>

      <p id="stateLabel">-</p>
      {loginButtons()}
      
      <h2>My Workspaces</h2>
      <div id="myWorkspaces"></div>
      <h2>Shared Workspaces</h2>
      <div id="sharedWorkspaces"></div>
    </>
  )
}

export default App
